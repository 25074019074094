import React from 'react'
import App from 'next/app'
import '../public/css/style.min.css'
import '../public/css/main.min.css'
import commonSession from "../common/commonSession"

class Layout extends React.Component {
    render () {
      const { children } = this.props
      return <div className='layout'>{children}</div>
    }
  }
  
  export default class MyApp extends App {
    render () {
      const { Component, pageProps } = this.props
      return (
        <Layout>
          <Component {...pageProps} />
        </Layout>
      )
    }
  }