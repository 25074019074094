/*
*
* 세션 정보를 관리하는 영역
*
*/

// dao 쪽에 토큰 정보를 넘기기 위해, 토큰 정보만 공유될 수 있도록 쿠키에 처리한다. 
import { setCookie } from "./cookieManager";

var commonSession = (function() {
  
    var getSession = function() {
        return {
            userId :  typeof window !== 'undefined' ? checkExpire(localStorage.getItem('userId'),'userId') : null,
            token : typeof window !== 'undefined' ? checkExpire(localStorage.getItem('token'),'token') : null,
            nickName : typeof window !== 'undefined' ? checkExpire(localStorage.getItem('nickName'),'nickName') : null,
            uid : typeof window !== 'undefined' ? checkExpire(localStorage.getItem('uid'),'uid') : null,
            firstName : typeof window !== 'undefined' ? checkExpire(localStorage.getItem('firstName'),'firstName') : null,
            lastName : typeof window !== 'undefined' ? checkExpire(localStorage.getItem('lastName'),'lastName') : null,
            inviterEmail : typeof window !== 'undefined' ? checkExpire(localStorage.getItem('inviterEmail'),'lastName') : null,
            enterprise : typeof window !== 'undefined' ? checkExpire(localStorage.getItem('enterprise'),'enterprise') : null,
            certificated : typeof window !== 'undefined' ? checkExpire(localStorage.getItem('certificated'),'certificated') : null,
        }
    };

    var checkExpire = function(itemStr,key){

        if(!itemStr){
            return null;
        }

        const item = JSON.parse(itemStr);
        const now  = new Date();

        console.log(now.getTime());
        console.log(item.expiry);

        if(now.getTime() > item.expiry){
            localStorage.removeItem(key);
            return null;
        }

        return item.value;
    }

    var setSession = function(userIdValue ,tokenValue ,nickNameValue,uidValue,firstNameValue,lastNameValue,inviterEmail,enterprise,certificated) {
        const now  = new Date();
        var ttl = 86400000;

        userIdValue != null ? localStorage.setItem('userId', JSON.stringify({'value':userIdValue,'expiry':now.getTime() + ttl})) : '';
        tokenValue != null ? localStorage.setItem('token', JSON.stringify({'value':tokenValue,'expiry':now.getTime() + ttl})) : '';

        // dao 에서 로그인 정보 공유 처리 
        if(tokenValue != null){
            setCookie('token', tokenValue , {
                domain: '.mym-b.com' // <- 추가 된 부분
            });
        }

        nickNameValue != null ? localStorage.setItem('nickName', JSON.stringify({'value':nickNameValue,'expiry':now.getTime() + ttl})) : '';
        uidValue != null ? localStorage.setItem('uid', JSON.stringify({'value':uidValue,'expiry':now.getTime() + ttl})) : '';
        firstNameValue != null ? localStorage.setItem('firstName', JSON.stringify({'value':firstNameValue,'expiry':now.getTime() + ttl})) : '';
        lastNameValue != null ? localStorage.setItem('lastName', JSON.stringify({'value':lastNameValue,'expiry':now.getTime() + ttl})) : '';
        inviterEmail != null ? localStorage.setItem('inviterEmail', JSON.stringify({'value':inviterEmail,'expiry':now.getTime() + ttl})) : '';
        enterprise != null ? localStorage.setItem('enterprise', JSON.stringify({'value':enterprise,'expiry':now.getTime() + ttl})) : '';
        certificated != null ? localStorage.setItem('certificated', JSON.stringify({'value':certificated,'expiry':now.getTime() + ttl})) : '';
    };
  
    return {
        getSession: getSession,
        setSession: setSession
    }
  
  })();
  
  export default commonSession;
